.root {
  width: 100%;
  height: 100%;
}

.node {
  font-size: 12px;
  line-height: 14px;
  cursor: default;
  user-select: none;
  align-items: flex-start;
  white-space: nowrap;
  position: relative;
  display: flex;
  padding: 5px 6px;

  &:hover {
    background: #ccc;
  }

  &>* {
    display: inline-block;
    vertical-align: baseline;
    padding: 0 2px;
  }
}

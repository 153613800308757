.root {
  width: 100%;
  height: 100%;
  overflow: auto;
  text-align: left;
}

.element-title {
  font-size: 14px;
  font-weight: bold;
  background: #ddd;
  display: inline-block;
  width: 100%;
  user-select: none;
  cursor: pointer;
  border: 1px solid #ccc;
}

.property-set-root {
  margin-left: 15px;
  .title {
    font-size: 14px;
    font-weight: bold;
    background: #ddd;
    > span {
      display: inline-block;
      width: 100%;
      user-select: none;
      cursor: pointer;
      border: 1px solid #ccc;
    }
  }
  .property-set {
    margin-left: 10px;
    .title > span {
      background: #e8e8e8;
      display: inline-block;
      width: 100%;
      user-select: none;
      cursor: pointer;
      border: 1px solid #ccc;
    }
    .property {
      margin: 2px 0 2px 10px;
      .entry.inactive .name {
        text-decoration: line-through;
      }
      .entry.inactive {
        filter: blur(2px);
      }
      .entry.inactive:hover {
        filter: blur(0);
      }
      .entry:nth-child(even) {
        background-color: #f3f3f3;
      }
      .entry .functions {
        margin: 1px 5px;
        display: none;
      }
      .entry:hover .functions {
        display: inline-block;
      }
      .name-wrapper {
        min-width: 100px;
        display: inline-block;
        vertical-align: middle;
        margin: 1px 3px 1px 0;
        padding: 1px 4px;
        border: 1px solid #ddd;
        background: #eee;
        text-align: center;
        font-weight: bold;
        text-shadow: 1px 1px 1px #fff;
        .name {
          font-size: 12px;
        }
        .plugin {
          font-size: 9px;
          color: #666;
        }
      }
    }
  }
}

.relation-root {
  margin-left: 15px;
  .title {
    font-size: 14px;
    font-weight: bold;
    background: #ddd;
    > span {
      display: inline-block;
      width: 100%;
      user-select: none;
      cursor: pointer;
      border: 1px solid #ccc;
    }
    .count {
      margin-left: 4px;
      color: #666;
      font-size: 13px;
    }
  }
  .relation-node {
    margin-left: 20px;
    .title {
      background: #e8e8e8;
    }
    .from-to-relations {
      padding: 3px 0;
      .relation-element {
        min-width: 100px;
        display: inline-block;
        vertical-align: middle;
        margin: 1px 3px 1px 0;
        padding: 1px 4px;
        border: 1px solid #ddd;
        background: #eee;
        text-align: center;
        font-weight: bold;
        text-shadow: 1px 1px 1px #fff;
        cursor: zoom-in;
        .id {
          font-size: 9px;
          color: #666;
        }
        .name {
          font-size: 12px;
        }
      }
    }
    .relation-element:hover {
      background: #fcfcfc;
    }
  }
}


.root {
    padding-top: 12px;
    font-size: 0.8em;
    width: 100%;
    height: 100%;
    background-color: rgb(240, 240, 240);
    position: relative;
    overflow: hidden;
}
.list-wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: scroll;
}
.storey-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    overflow-y: visible;
}
.storey-list{
    width: 100%;
    padding-left: 1%;
    padding-right: 1%;
}
.storey-item{
    width: 100%;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
}

.filter-box{
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
}

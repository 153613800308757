:global {
  .hljs {
    display: block;
    overflow-x: auto;
    background: #f5f7fa;
    color: #333;
    padding: 0.5em;
    cursor: default;
  }

  .hljs-keyword {
    color: #500;
    font-weight: bold;
  }

  .hljs-string {
    color: #060;
  }

  .hljs-computational {
    color: #a75;
  }

  .hljs-compare {
    color: #500;
    font-weight: bold;
  }

  .hljs-number {
    color: #905;
  }

  .hljs-variable {
    color: #840;
  }

  .hljs-property {
    color: #07a;
  }

  .hljs-pronoun {
    color: #777;
  }

  .hljs span {
    //border: 1px solid transparent;
  }

  .hljs span:hover {
    //border: 1px solid #000;
    background: #ddd;
  }
}

.root {
  max-width: 2000px;
  padding: 5px 15px;
  width: 100%;
  height: 100%;
  overflow: auto;
  margin: 0 auto;
  text-align: left;
  font-size: 18px;
}
.input-area{
  font-size: 22px;
}
.btn-large {
    font-size: 22px; /* 调整字体大小 */
    height: 100%; /* 使按钮高度与搜索框一样 */
    line-height: inherit; /* 确保按钮内文本垂直居中 */
}
.big-font {
  font-size: 26px;
  //加粗bold
  font-weight: bold;
}
.middle-font {
  font-size: 20px;
  font-weight: bold;
}
.small-font {
  font-size: 18px;
}

body {
  background: #f5f5f5;
}
:global .ant-tree-show-line,
:global .ant-tree-show-line {
  background: transparent !important;
}
* {
  animation-duration: 0s !important;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 0;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}
* {
  scrollbar-color: #ddd #f5f5f5;
  scrollbar-width: thin;
}
#root::-webkit-scrollbar {
  width: auto;
}
#root {
  scrollbar-width: auto;
}
.flexlayout__tab {
  overflow: hidden;
}
.root {
  background: #eee;
  min-height: calc(100vh - 40px);
  padding: 30px;
}
.list-wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding: 10px;
  background: #fff;
  text-align: left;
}
.list-wrapper h1 {
  text-align: center;
}
.list-wrapper h1 img {
  width: 40px;
}
.list-wrapper .project-name-input {
  width: calc(100% - 200px);
  max-width: 400px;
}
.list-wrapper .pagination {
  text-align: center;
}
.project-name-input-group {
  text-align: center;
}
.avatar {
  background: #650874;
}

.window-wrapper {
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
}

.dock-wrapper {
  flex: 1;
  position: relative;
}

.fps {
  position: fixed;
  width: 80px;
  height: 40px;
  right: 1px;
  top: 40px;
  z-index: 50;
  background: rgba(0, 0, 0, 0.4);
  transform: scale(0.9);
  transform-origin: top right;
}

.fps > div > div {
  position: relative !important;
}


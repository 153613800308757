.result-wrapper {
  padding: 5px 10px;
  text-align: left;
}

.rule-item {
  background: #fff;
}

.rule-item-title {
  position: sticky;
  top: 0;
  font-size: 16px;
  font-weight: bold;
  background: #fff;
  box-shadow: 0 5px 3px -3px #888;
  padding: 2px 0;
  > span {
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    font-size: 16px;
    line-height: 16px;
  }
}

.check-item {
  padding: 4px 4px;
}

.result-table {
  margin: 5px 0 18px;
  width: 100%;
  border: 1px solid #aaa;
  td {
      border: 1px solid #aaa;
      padding: 2px 5px;
      word-break: break-word;
    .title {
      background: #ccc;
      font-weight: bold;
    }
  }
}

.element-id-button {
  margin: 2px 5px;
  //display: inline-block;
  min-width: 40px;
  text-align: center;
  //background: #fcc;
  //color: #000;
  //border: 1px solid #333;
  //padding: 2px;
  //border-radius: 4px;
  //text-shadow: 1px 1px 1px #fff;
  cursor: pointer;
  //&:hover {
  //  background: #fee;
  //}
}

.item-pass {
  border: 1px solid #555;
  background: #ccf;
  padding: 2px 5px;
  margin: 0 3px
}


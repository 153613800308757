.root {
  margin: 14px auto;
  max-width: 1200px;
}

.panel {
  background: white;
  min-height: 500px;
  height: 100%;
  width: 100%;
}

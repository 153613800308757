.root {
  text-align: left;
  width: 100%;
  height: 100%;
  overflow: auto;
  font-family: Consolas, '微软雅黑', monospace;

  .summary {
    text-align: center;
  }
  .row {
    position: relative;
    white-space: nowrap;
    border-bottom: 1px dashed #666;
    padding: 1px 5px;
    .progress-background {
      position: absolute;
      box-sizing: border-box;
      top: 0;
      left: 0;
      height: 100%;
      background: #0f0;
      z-index: 1;
    }
    .row-content {
      z-index: 2;
      position: relative;
      > div {
        display: inline-block;
        position: relative;
        vertical-align: middle;
      }
      .status {
        font-weight: bold;
        width: 55px;
      }
      .time-block {
        display: inline-block;
        position: relative;
        font-size: 11px;
        .time-cell {
          display: inline-block;
          border-left: 1px solid #ccc;
          line-height: 1;
          padding: 1px;
          margin: 2px 2px;
          font-family: Consolas, '微软雅黑', monospace;
          text-align: right;
        }
        .time-cell:last-child {
          border-right: 1px solid #ccc;
        }
      }
    }
  }
}

.rule {
  position: relative;
  margin: 0;
  text-align: left;

  .head {
    scroll-margin-top: 0;
    position: sticky;
    top: 0;
    padding: 5px 10px;
    background: #ddd;
    border-top: 1px solid #bbb;
    border-bottom: 1px solid #bbb;
    font-size: 16px;
    font-weight: bold;
  }

  .body {
    margin: 5px 10px;

    .des {
      color: #000;
      font-size: 16px;
    }

    .split-text {
      color: #000;
      font-size: 14px;
      margin-left: 10px;
    }

    .snl {
      color: #005;
      font-size: 12px;
      margin-left: 10px;
      border-left: 4px solid #99f;
      background: #eee;
    }

    .tooltip-wrapper {
      position: relative;
      min-height: 30px;
    }

    .tooltip {
      color: #005;
      font-size: 12px;
      border-left: 4px solid #f99;
      background: #eee;
      position: absolute;
      padding: 2px 10px;
      width: calc(100% - 10px);
      top: 0;
      left: 10px;
    }
  }
}

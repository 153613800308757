body {
  background: #f5f5f5;
}
:global .ant-tree-show-line,
:global .ant-tree-show-line {
  background: transparent !important;
}
* {
  animation-duration: 0s !important;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 0;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}
* {
  scrollbar-color: #ddd #f5f5f5;
  scrollbar-width: thin;
}
#root::-webkit-scrollbar {
  width: auto;
}
#root {
  scrollbar-width: auto;
}
.flexlayout__tab {
  overflow: hidden;
}
.menu-bar {
  background: #650874;
  padding: 0 20px;
  border-bottom: solid 1px #e8e8e8;
  box-shadow: 0 0 30px #f3f1f1;
  display: flex;
  height: 40px;
  overflow: hidden;
}
.logo {
  flex: 1 1 auto;
  white-space: nowrap;
}
.logo a {
  color: #fff;
  display: inline-block;
  font-size: 20px;
  padding: 3px 5px;
  text-transform: capitalize;
  white-space: nowrap;
}
.logo img {
  width: 36px;
  height: 36px;
  vertical-align: top;
  margin-top: 1px;
  margin-right: 8px;
}
.menu-main {
  width: calc(100% - 150px);
  flex: 1 1 auto;
}
.menu-main :local .ant-menu-item {
  padding: 0 5px;
}
.menu-main :local .ant-menu-item a {
  padding: 10px 15px;
}
.menu-main :local .ant-menu-submenu-title {
  padding: 10px 20px;
}
.menu-main :local .ant-menu-submenu-title a {
  padding: 10px 15px;
}
.menu-main :local .ant-menu-horizontal {
  border-bottom: none;
}
.menu-main .left-menu {
  float: left;
}
.menu-main .right-menu {
  float: right;
}
.bars-menu {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 8px;
  display: none;
  background: none;
}
.bars-menu > span {
  display: block;
}
.bars-btn {
  display: block;
  width: 20px;
  height: 2px;
  background: #1890ff;
  position: relative;
}
.bars-btn:after {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: #1890ff;
  bottom: -6px;
}
.bars-btn:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: #1890ff;
}
:local .ant-drawer-body {
  padding: 0;
}
:local .ant-drawer-body :local .ant-menu-horizontal {
  border-bottom: none;
}
:local .ant-drawer-body :local .ant-menu-horizontal > .ant-menu-item {
  display: inline-block;
  width: 100%;
}
:local .ant-drawer-body :local .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent;
}
:local .ant-drawer-body :local .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%;
}
@media (max-width: 767px) {
  .bars-menu {
    display: inline-block;
  }
  .left-menu {
    display: none;
  }
  .right-menu {
    display: none;
  }
  .logo a {
    margin-left: -20px;
    padding: 10px 20px;
  }
  .menu-main :local .ant-menu-item {
    padding: 1px 20px;
  }
  .menu-main :local .ant-menu-submenu-title {
    padding: 1px 20px;
  }
}
.login-wrapper {
  flex: 1 0 auto;
  color: #fff;
}
.login-wrapper .logged-in {
  height: 100%;
  line-height: 36px;
}
.login-wrapper .logged-in :last-child {
  margin-left: 8px;
  cursor: pointer;
}
.login-wrapper .logging-in a {
  color: #fff;
  line-height: 38px;
}

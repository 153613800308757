.root {
    padding-top: 12px;
    font-size: 0.8em;
}
.single-col{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    text-align: center;
}

.mdx-wrapper {
  max-width: 920px;
  padding: 5px 15px;
  width: 100%;
  margin: 0 auto;
  text-align: left;
  font-size: 18px;

  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 27px;
  }

  p {
    font-size: 18px;
  }

  img {
    max-width: 100%;
  }

  img ~ {
    text-align: center;
  }
}

@keyframes MOVE-BG {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(55px);
  }
}

.loading {
  width: 100%;
  height: 100%;
  text-align: center;
  color: #4c3f2e;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 99999;
  //pointer-events: none;
}

.text {
  position: absolute;
  top: 50%;
  width: 100%;
  font-size: 32px;
  text-align: center;
  color: #333364;
  text-shadow: 0 0 10px #fff;
  text-effect: outline;
  transform: translate(0, -50%);
}

.bg{
  position: absolute;
  left: -55px;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;

  background: repeating-linear-gradient(
          -45deg,
          rgba(0,0,100,0.3) 1px,
          transparent 2px,
          transparent 11px,
          rgba(0,0,100,0.3) 12px,
          rgba(0,0,100,0.3)  20px
  );

  animation-name: MOVE-BG;
  animation-duration: 1s !important;  // 由于全局关闭动画选项，这里需要强制启用
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}


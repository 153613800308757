// antd 覆盖样式
@primary-color: #650874;
@layout-header-background: #650874;
//@tree-directory-selected-bg: lighten(@primary-color, 50%);
@tree-directory-selected-bg: #ece1ee;
@tree-directory-selected-color: #000;
@tree-child-padding: 10px;
@text-color: rgba(0, 0, 0, 1); // 主文本色，默认完全黑色，清晰

// 全局样式
body {
  background: #f5f5f5;
}

// antd 强制修改样式
:global .ant-tree-show-line,
:global .ant-tree-show-line {
  background: transparent !important;
}

// 自定义样式
@navbar-color: @primary-color;

// 移除全局的动画，快速响应
* {
  animation-duration: 0s !important;
}

// 自定义火狐和chrome通用的滚动条样式
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 0;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

// Firefox 浏览器的滚动条样式
* {
  scrollbar-color: #ddd #f5f5f5;
  scrollbar-width: thin;
}

// 还原root元素的滚动条，避免全局页面不好拖动
#root::-webkit-scrollbar {
  width: auto;
}

#root {
  //scrollbar-color: #ddd #f5f5f5;
  scrollbar-width: auto;
}

// 自定义FlexLayout样式
.flexlayout__tab {
  overflow: hidden;
}

//.flexlayout__splitter {
//  z-index: 999999;
//}
.viewer-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  user-select: none;
}

.loading-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: #fff;
}

.background-layer {
  //background: #ccc;
  //background: #444;

  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.semantic-layer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 300;
}

.modifier-layer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 400;
  pointer-events: none;
}

.tuzhi-layer-canvas {
  background: #ccc;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.thumbnail {
  position: absolute;
  width: 100%;
  height: 30px;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background: rgba(255,255,255,0.8);
  color: black;
  text-align: left;
  line-height: 30px;
  padding: 0 10px;
  border-top: 1px solid #ccc;
  font-family: Consolas, "Microsoft YaHei", sans-serif;
}

.abstract {
  position: absolute;
  width: 300px;
  height: 300px;
  right: 0;
  bottom: 30px;
  z-index: 1000;
  background: rgba(255,255,255,0.8);
  text-align: left;
  overflow-y: auto;
  resize: horizontal;
}

.ifc-viewer {
  width: 100%;
  height: 100%;
  background: #f00;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
}

.node {
  white-space: nowrap;
  cursor: pointer;
  user-select: none;

  &:hover {
    font-weight: bold;
    color: #1890ff;
  }
}


.root {
  margin: 0 auto;
  text-align: left;
  font-size: 18px;
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
}
.project-title{
  text-align: center;
  font-size: 1rem;
}
.dock-wrapper{
  flex: 1;
  position: relative;
}
.unbound-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.panel-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: stretch;
  text-align: left;
}

.tools {
  flex: 0 10px;
  text-align: center;
  margin-top: 5px;
}

.tree-wrapper {
  flex: 1;
  width: 100%;
  overflow: auto;

  :global .ant-tree-node-content-wrapper {
    white-space: nowrap;
  }

  :global .ant-tree-iconEle {
    float: left;
  }

  :global .ant-tree-title {
    display: block;
  }

  :global .ant-tree-switcher {
    width: 22px;
    background: transparent;
  }
}

.toolbar {
  position: absolute;
  bottom: -1px;
  right: -1px;
  width: 350px;
  height: 67px;
  padding: 4px 6px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid #ccc;
  pointer-events: auto;
  z-index: 600;
}

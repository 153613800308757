.wrapper {
  width: 100%;
  height: 100%;

  :global .flexlayout__tab {
    > * {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
  }

  .submit {
    padding: 10px;

    .current-snl {
      width: 100%;
      //white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .local-snl {
      width: 100%;
      margin: 0 auto 8px;
      :global .ant-upload {
        width: 100%;
      }
    }
  }

  .resultList {

    .result-list-item {
      flex: 1;
      padding: 6px;

      .detail {
        display: flex;
        justify-content: center;

        .func-wrapper {
          width: 130px;
          text-align: left;
          * {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .group {
            text-align: center;
          }
        }
        .time-wrapper {
          width: 150px;
          white-space: nowrap;
        }
      }
    }

    .result-list-icon {
      width: 100%;
      flex: 0;
    }

    .result-list-title {
      flex: 1;
    }
  }

  .report {
    display: flex;
    flex-direction: column;

    .checks {
      flex: 0 0;
      border-bottom: 1px solid #eee;
    }

    .main {
      flex: 1;
      display: flex;
      flex-direction: row;
      overflow: hidden;

      .tree {
        text-align: left;
        color: black;
        height: 100%;
        overflow: auto;
        width: 200px;
        resize: horizontal;
      }

      .list {
        flex: 1;
        width: 100%;
        overflow: scroll;
      }
    }
  }
}




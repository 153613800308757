.root {
  width: 100%;
  height: 100%;
}

.dag {
  width: 100%;
  height: 100%;
  text-align: left;
}

.root{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  background-color: rgb(226, 226, 226);
}
.project-list-card{
  margin-top: 30px;
  min-width: 600px;
  width: 50%;
  background-color: rgb(255, 255, 255);
}
.card-title{
  text-align: center;
  font-size: 1.2rem;
}
.search-wrap{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 30px 16px 30px;
}
.project-list{

}
.nonend-button{
  margin-right: 20px;
}

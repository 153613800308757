.storey-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 50%;
    background-color: rgb(255, 255, 255);
}
.storey-list{
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 10px;
    border-radius: 20px;
}
.storey-item{
    width: 100%;
    background-color: rgb(232, 232, 232);
}
.panel-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: stretch;
    text-align: left;
  }
  
  .tools {
    flex: 0 10px;
    text-align: center;
    margin-top: 5px;
  }
  
  .tree-wrapper {
    flex: 1;
    width: 100%;
    overflow: auto;
  
    :global .ant-tree-node-content-wrapper {
      white-space: nowrap;
    }
  
    :global .ant-tree-iconEle {
      float: left;
    }
  
    :global .ant-tree-title {
      display: block;
    }
  
    :global .ant-tree-switcher {
      width: 22px;
      background: transparent;
    }
    .tree-item {
      display: flex;
      .name {
        flex: 1;
        white-space: nowrap;
      }
      .buttons {
        flex: 0;
      }
    }
  }

.root {
    width: 100%;
    height: 100%;
    overflow: auto;
    text-align: center;
    padding-top: 10px;
    padding-left: 4px;
    padding-right: 4px;
  }

.title{
  background-color: rgb(233, 233, 233);
}


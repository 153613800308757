.root {
  width: 100%;
  height: 100%;
  overflow: auto;
  text-align: left;
}

.element-title {
  font-size: 14px;
  font-weight: bold;
  background: #ddd;
  display: inline-block;
  width: 100%;
  user-select: none;
  cursor: pointer;
  border: 1px solid #ccc;
}

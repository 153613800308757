.root {
  width: 100%;
  height: 100%;
  position: relative;
}

.ifc-viewer {
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
}

.root {
  width: 100%;
  height: 100%;
}

.json-viewer {
  text-align: left;
  height: 100%;
  //overflow: scroll;
}
